import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from "vuex-persistedstate"

Vue.use(Vuex)

const getDefaultState = () => {
  return {
    login : false,
    userid : "",
    username : "",
    token : '',
    companyId : '',
    memberNo : '',
    attribute : 0,
    association : null,
    frozenAssociation : null,

    reservedispday: '',
    reserveprogno: 0,

    targetSurveyType: null,

    mode: '',
    editId: '',

    targetSurveyId: '',
    targetMemberNo: '',
    targetBranch: '',
    targetBranchName: '',

    storeSearchCondition: {},

    selectAssociationList: {},

    formChanged: false,

    // マイページ
    mypageList: [],
  }
}

export default new Vuex.Store({
  state: getDefaultState(),

  getters: {
    // login status を取得
    getLoginStat: state => {
      return state.login;
    },

    // user 情報 を取得
    getUserid: state => {
      return state.userid;
    },
    getUsername: state => {
      return state.username;
    },
    getCompanyId: state => {
      return state.companyId;
    },
    getMemberNo: state => {
      return state.memberNo;
    },
    getAttribute: state => {
      return state.attribute;
    },
    getAssociation: state => {
        return state.association;
    },
    getFrozenAssociation: state => {
        return state.frozenAssociation;
    },

    // api token を取得
    getToken: state => {
      return state.token;
    },

    getTargetSurveyType: state => {
      return state.targetSurveyType;
    },

    getMode: state => {
      return state.mode;
    },

    getEditId: state => {
      return state.editId;
    },

    getTargetSurveyId: state => {
      return state.targetSurveyId;
    },

    getTargetMemberNo: state => {
      return state.targetMemberNo;
    },

    getTargetBranch: state => {
      return state.targetBranch;
    },

    getTargetBranchName: state => {
      return state.targetBranchName;
    },

    getStoreSearchCondition: state => {
      return state.storeSearchCondition;
    },

    getSelectAssociationList: state => {
      return state.selectAssociationList;
    },

    getFormChanged: state => {
        return state.formChanged;
    },

    // マイページ
    getMypageList: state => {
        return state.mypageList;
    },
},

  mutations: {
    // login status を保存
    setLoginStat(state, login) {
      state.login = login;
    },

    // user 情報 を保存
    setUserid(state, userid) {
      state.userid = userid;
    },
    setUsername(state, username) {
      state.username = username;
    },
    setCompanyId(state, companyId) {
      state.companyId = companyId;
    },
    setMemberNo(state, memberNo) {
      state.memberNo = memberNo;
    },
    setAttribute(state, attribute) {
      state.attribute = attribute;
    },
    setAssociation(state, association) {
        state.association = association;
    },
    setFrozenAssociation(state, frozenassociation) {
        state.frozenAssociation = frozenassociation;
    },

    // api token を保存
    setToken(state, token) {
      state.token = token;
    },

    setTargetSurveyType(state, targetSurveyType) {
      state.targetSurveyType = targetSurveyType;
    },

    setMode(state, mode) {
      state.mode = mode;
    },

    setEditId(state, editId) {
      state.editId = editId;
    },

    setTargetSurveyId(state, targetSurveyId) {
      state.targetSurveyId = targetSurveyId;
    },

    setTargetMemberNo(state, targetMemberNo) {
      state.targetMemberNo = targetMemberNo;
    },

    setTargetBranch(state, targetBranch) {
      state.targetBranch = targetBranch;
    },

    setTargetBranchName(state, targetBranchName) {
      state.targetBranchName = targetBranchName;
    },

    setStoreSearchCondition(state, storeSearchCondition) {
      state.storeSearchCondition = storeSearchCondition;
    },

    setSelectAssociationList(state, selectAssociationList) {
      state.selectAssociationList = selectAssociationList;
    },

    // マイページ
    setMypageList(state, mypageList) {
        state.mypageList = mypageList;
    },

    setFormChanged(state, formChanged) {
      state.formChanged = formChanged;
    },

    // stateの初期化
    reset(state) {
      Object.assign(state, getDefaultState());
    },
  },

  actions: {
  },

  modules: {
  },

  // `createPersistedState()`でインスタンス作成。引数に設定を書く
  plugins: [createPersistedState(
    {
       // ストレージのキーを指定。デフォルトではvuex
      key: 'App_Nichireisou',

      // 管理対象のステートを指定。pathsを書かない時は`modules`に書いたモジュールに含まれるステート全て。`[]`の時はどれも保存されない
      paths: [
        'login',
        'userid',
        'username',
        'token',
        'companyId',
        'memberNo',
        'attribute',
        'association',
        'frozenAssociation',
        'targetSurveyType',
        'mode',
        'editId',
        'targetSurveyId',
        'targetMemberNo',
        'targetBranch',
        'targetBranchName',
        'storeSearchCondition',
        'formChanged',
        'mypageList',
      ],

      // ストレージの種類を指定する。デフォルトではローカルストレージ
      storage: window.sessionStorage
    }
  )]
})
