import Axios from 'axios';
import Cookies from 'vue-cookies';
import Debug from '../Lib/Debug'
import Message from '../Lib/Message'
import Store from '../store/index'
import Router from '../router/index'

// リクエストを送信する前の処理
Axios.interceptors.request.use(async (request) => {
  // Debug.log(request)
  if ( !((request.url == "/api/login") 
      || (request.url == "/api/refresh")
      || (request.url.indexOf("/api/reset_password") === 0)
  ) ) {

    const new_token = await checkToken();

    if (new_token != null) {
      request.headers['Authorization'] = 'Bearer ' + new_token;
    }
  }

  return request
});

// レスポンスが戻ってきた際の処理
Axios.interceptors.response.use(response => {
  // Debug.log(response.config)
  AuthCheck(response)
  
  return response
})


/**
 * token の期限を確認
 * @param {*}
 * @returns
 */
async function checkToken() {
  // Debug.log("<axios-Token Check>");

  const token = sessionStorage.getItem("token");
  const tokeninfo = decodeJwt(token);
  const now = Date.now().valueOf() / 1000;

  // token の refresh 処理
  // Debug.log("token limit   : " + new Date(tokeninfo.exp * 1000).toLocaleString());
  // Debug.log("now date/time : " + new Date(now * 1000).toLocaleString());

  // tokenの期限を確認
  if (tokeninfo.exp >= now) return null;

  try {
      // Refresh Tokenがある場合にはそちらを使う
      let refreshToken = sessionStorage.getItem("token");
      if (sessionStorage.getItem("refresh")) {
        refreshToken = sessionStorage.getItem("refresh");
      }
    
    const config = {
      headers: {
        'Authorization': 'Bearer ' + refreshToken
      }
    }
    const res = await Axios.get(`/api/refresh`, config);
    // 保管している token を更新
    sessionStorage.setItem("token", res.data.access_token);
    // cookieにも保存
    Cookies.set('token', res.data.access_token);

    Debug.log(res);
    Debug.log("old token : " + token);
    // Debug.log("new token : " + res.data.access_token);
    Debug.log("new token : " + sessionStorage.getItem("token"));

  } catch (error) {
    const message = "token の更新に失敗しました";
    AuthCheck(error.response);
    // console.error(message);
    Message.err(error, message);
  }

  return sessionStorage.getItem("token");
}

/**
 * token をデコードする
 * @param {*} token
 * @returns
 */
function decodeJwt(token) {
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  return JSON.parse(decodeURIComponent(escape(window.atob(base64))));
}

/**
 * APIのレスポンスが 401(Unauthorized) の場合ログアウトさせる
 */
function AuthCheck(response) {
  if (response) {
      if (response.status == 401) {
          alert('ユーザ認証エラーです。ログアウトします。');
          // ログアウト処理
          Store.commit('setLoginStat', false);
          // ログイン画面に遷移
          Router.push('/login');
          // 処理を中断させる
          throw new Error();      
      }
  }
}
