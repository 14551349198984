import Vue from 'vue'
import VueRouter from 'vue-router'
import Store from '../store/index'

const Login = () => import(/* webpackChunkName: "Login" */ "../views/Login.vue")
const ResetPassword = () => import(/* webpackChunkName: "Login" */ "../views/ResetPassword.vue")
const Sidebar = () => import(/* webpackChunkName: "Sidebar" */ "../views/Sidebar.vue")

const TopView = () => import(/* webpackChunkName: "TopView" */ "../components/TopView.vue")
const SurveyTop = () => import(/* webpackChunkName: "SurveyTop" */ "../components/SurveyTop.vue")
const FactFindingList = () => import(/* webpackChunkName: "FactFindingList" */ "../components/FactFindingList.vue")
const FactFindingEdit = () => import(/* webpackChunkName: "FactFindingEdit" */ "../components/FactFindingEdit.vue")
const ElectricityList = () => import(/* webpackChunkName: "ElectricityList" */ "../components/ElectricityList.vue")
const ElectricityEdit = () => import(/* webpackChunkName: "ElectricityEdit" */ "../components/ElectricityEdit.vue")
const RefrigerantList = () => import(/* webpackChunkName: "RefrigerantList" */ "../components/RefrigerantList.vue")
const RefrigerantEdit = () => import(/* webpackChunkName: "RefrigerantEdit" */ "../components/RefrigerantEdit.vue")
const CityDataList = () => import(/* webpackChunkName: "CityDataList" */ "../components/CityDataList.vue")
const CityDataEdit = () => import(/* webpackChunkName: "CityDataEdit" */ "../components/CityDataEdit.vue")
const CreateMemberRoster = () => import(/* webpackChunkName: "CreateMemberRoster" */ "../components/CreateMemberRoster.vue")
const RequestAssociationFee = () => import(/* webpackChunkName: "RequestAssociationFee" */ "../components/RequestAssociationFee.vue")
const RequestAssociationFeeMail = () => import(/* webpackChunkName: "RequestAssociationFeeMail" */ "../components/RequestAssociationFeeMail.vue")
const MailSendHistoryList = () => import(/* webpackChunkName: "MailSendHistoryList" */ "../components/MailSendHistoryList.vue")

const SurveyManage = () => import(/* webpackChunkName: "SurveyManage" */ "../components/SurveyManage.vue")
const SurveySend = () => import(/* webpackChunkName: "SurveySend" */ "../components/SurveySend.vue")
const SurveyList = () => import(/* webpackChunkName: "SurveyList" */ "../components/SurveyList.vue")

const SurveyCheck = () => import(/* webpackChunkName: "SurveyCheck" */ "../components/SurveyCheck.vue")
const SurveySummary = () => import(/* webpackChunkName: "SurveySummary" */ "../components/SurveySummary.vue")
const SurveyReply = () => import(/* webpackChunkName: "SurveyReply" */ "../components/SurveyReply.vue")

const MemberList = () => import(/* webpackChunkName: "MemberList" */ "../components/MemberList.vue")
const MemberEdit = () => import(/* webpackChunkName: "MemberEdit" */ "../components/MemberEdit.vue")
const MemberUpdateHistory = () => import(/* webpackChunkName: "MemberUpdateHistory" */ "../components/MemberUpdateHistory.vue")

const Mcompany = () => import(/* webpackChunkName: "McompanyList" */ "../components/McompanyList.vue")
const AssociationList = () => import(/* webpackChunkName: "AssociationList" */ "../components/AssociationList.vue")
const AssociationEdit = () => import(/* webpackChunkName: "AssociationEdit" */ "../components/AssociationEdit.vue")
const UserList = () => import(/* webpackChunkName: "UserList" */ "../components/UserList.vue")

const MailTemplateList = () => import(/* webpackChunkName: "MailTemplateList" */ "../components/MailTemplateList.vue")
const MailTemplateEdit = () => import(/* webpackChunkName: "MailTemplateEdit" */ "../components/MailTemplateEdit.vue")

const InfoMailSend = () => import(/* webpackChunkName: "InfoMailSend" */ "../components/InfoMailSend.vue")

const MrefrigerantList = () => import(/* webpackChunkName: "MrefrigerantList" */ "../components/master/MrefrigerantList.vue")

const MailSendGroup = () => import(/* webpackChunkName: "MailSendGroupList" */ "../components/MailSendGroupList.vue")

/** 国交省データ登録 */
const MlitDataInput = () => import(/* webpackChunkName: "MlitDataInput" */ "../components/mlit/MlitDataInput.vue")
const MlitDataList = () => import(/* webpackChunkName: "MlitDataList" */ "../components/mlit/MlitDataList.vue")
const MlitOfficeList = () => import(/* webpackChunkName: "MlitDataList" */ "../components/mlit/MlitOfficeList.vue")
const MlitCreateDocument = () => import(/* webpackChunkName: "MlitCreateDocument" */ "../components/mlit/MlitCreateDocument.vue")

Vue.use(VueRouter)

const routes = [
  {
    path: '/',      redirect: { name: 'TopView' }
  },
  {
    path: '/login',     name: 'Login',
    component: Login,
    meta: { isPublic: true },
  },
  {
    path: '/reset_password',     name: 'ResetPassword',
    component: ResetPassword,
    meta: { isPublic: true },
  },
  {
    path: '/mail/resetpass/:remember_token',     name: 'ResetPasswordInput',
    component: ResetPassword,
    meta: { isPublic: true },
  },

  {
    path: '/top_view',     name: 'TopView',
    components: { default: TopView, sidebar: Sidebar },
  },
  {
    path: '/survey_top',     name: 'SurveyTop',
    components: { default: SurveyTop, sidebar: Sidebar },
  },
  {
    path: '/factfinding_list',     name: 'FactFindingList',
    components: { default: FactFindingList, sidebar: Sidebar },
  },
  {
    path: '/factfinding_edit',     name: 'FactFindingEdit',
    components: { default: FactFindingEdit, sidebar: Sidebar },
  },
  {
    path: '/electricity_list',     name: 'ElectricityList',
    components: { default: ElectricityList, sidebar: Sidebar },
  },
  {
    path: '/electricity_edit',     name: 'ElectricityEdit',
    components: { default: ElectricityEdit, sidebar: Sidebar },
  },
  {
    path: '/refrigerant_list',     name: 'RefrigerantList',
    components: { default: RefrigerantList, sidebar: Sidebar },
  },
  {
    path: '/refrigerant_edit',     name: 'RefrigerantEdit',
    components: { default: RefrigerantEdit, sidebar: Sidebar },
  },
  {
    path: '/city_data_list',     name: 'CityDataList',
    components: { default: CityDataList, sidebar: Sidebar },
  },
  {
    path: '/city_data_edit',     name: 'CityDataEdit',
    components: { default: CityDataEdit, sidebar: Sidebar },
  },
  {
    path: '/member_list',     name: 'MemberList',
    components: { default: MemberList, sidebar: Sidebar },
    meta: { isAssociation: true },
  },
  {
    path: '/member_edit',     name: 'MemberEdit',
    components: { default: MemberEdit, sidebar: Sidebar },
    meta: { isAssociation: true },
  },
  {
    path: '/member_edit_mypage',     name: 'MemberEditMypage',
    components: { default: MemberEdit, sidebar: Sidebar },
  },
  {
    path: '/member_update_history',     name: 'MemberUpdateHistory',
    components: { default: MemberUpdateHistory, sidebar: Sidebar },
    meta: { isNichireisou: true },
  },
  {
    path: '/company_list',     name: 'Mcompany',
    components: { default: Mcompany, sidebar: Sidebar },
    meta: { isAssociation: true },
  },
  {
    path: '/association_list',     name: 'AssociationList',
    components: { default: AssociationList, sidebar: Sidebar },
    meta: { isAssociation: true },
  },
  {
    path: '/association_edit',     name: 'AssociationEdit',
    components: { default: AssociationEdit, sidebar: Sidebar },
    meta: { isAssociation: true },
  },
  {
    path: '/association_edit_mypage',     name: 'AssociationEditMypage',
    components: { default: AssociationEdit, sidebar: Sidebar },
    meta: { isAssociation: true },
  },
  {
    path: '/user_list',     name: 'UserList',
    components: { default: UserList, sidebar: Sidebar },
    meta: { isNichireisou: true },
  },

  {
    path: '/create_member_roster',     name: 'CreateMemberRoster',
    components: { default: CreateMemberRoster, sidebar: Sidebar },
    meta: { isNichireisou: true },
  },
  {
    path: '/request_association_fee/:type',     name: 'RequestAssociationFee',
    components: { default: RequestAssociationFee, sidebar: Sidebar },
    meta: { isNichireisou: true },
  },
  {
    path: '/request_association_fee_mail',     name: 'RequestAssociationFeeMail',
    components: { default: RequestAssociationFeeMail, sidebar: Sidebar },
    meta: { isNichireisou: true },
  },
  {
    path: '/survey_manage',     name: 'SurveyManage',
    components: { default: SurveyManage, sidebar: Sidebar },
    meta: { isAssociation: true },
  },
  {
    path: '/survey_send',     name: 'SurveySend',
    components: { default: SurveySend, sidebar: Sidebar },
    meta: { isNichireisou: true },
  },
  {
    path: '/survey_list',     name: 'SurveyList',
    components: { default: SurveyList, sidebar: Sidebar },
    meta: { isAssociation: true },
  },
  {
    path: '/survey_check',     name: 'SurveyCheck',
    components: { default: SurveyCheck, sidebar: Sidebar },
    meta: { isAssociation: true },
  },
  {
    path: '/survey_summary',     name: 'SurveySummary',
    components: { default: SurveySummary, sidebar: Sidebar },
    meta: { isAssociation: true },
  },
  {
    path: '/survey_reply',     name: 'SurveyReply',
    components: { default: SurveyReply, sidebar: Sidebar },
    meta: { isNichireisou: true },
  },

  {
    path: '/mail_template_list',     name: 'MailTemplateList',
    components: { default: MailTemplateList, sidebar: Sidebar },
    meta: { isNichireisou: true },
  },
  {
    path: '/mail_template_edit',     name: 'MailTemplateEdit',
    components: { default: MailTemplateEdit, sidebar: Sidebar },
    meta: { isNichireisou: true },
  },

  {
    path: '/info_mail_send',     name: 'InfoMailSend',
    components: { default: InfoMailSend, sidebar: Sidebar },
    meta: { isNichireisou: true },
  },

  {
    path: '/m_refrigerant_list',     name: 'MrefrigerantList',
    components: { default: MrefrigerantList, sidebar: Sidebar },
    meta: { isNichireisou: true },
  },

  {
    path: '/mail_send_history_list',     name: 'MailSendHistoryList',
    components: { default: MailSendHistoryList, sidebar: Sidebar },
    meta: { isNichireisou: true },
  },
  
  {
    path: '/mail_send_group_list',     name: 'MailSendGroup',
    components: { default: MailSendGroup, sidebar: Sidebar },
    meta: { isNichireisou: true },
  },

  /** 国交省データ登録 */
  {
    path: '/mlit_data_input',     name: 'MlitDataInput',
    components: { default: MlitDataInput, sidebar: Sidebar },
    meta: { isNichireisou: true },
  },

  {
    path: '/mlit_data_list',     name: 'MlitDataList',
    components: { default: MlitDataList, sidebar: Sidebar },
    meta: { isNichireisou: true },
  },

  {
    path: '/mlit_office_list',     name: 'MlitOfficeList',
    components: { default: MlitOfficeList, sidebar: Sidebar },
    meta: { isNichireisou: true },
  },

  {
    path: '/mlit_create_document',     name: 'MlitCreateDocument',
    components: { default: MlitCreateDocument, sidebar: Sidebar },
    meta: { isNichireisou: true },
  },

  // 上記以外は TOPへリダイレクト
  { path: '/*', redirect: 'top_view' }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router


// ログイン状態のチェックを行う
router.beforeEach((to, from, next) => {
  // const fromP = from.fullPath.split('_')[0];
  // const toP = to.fullPath.split('_')[0];
  // if(fromP != toP) {
  //   // 検索条件の初期化
  //   Store.commit('setStoreSearchCondition', {});
  // }
  // if (!to.matched.some(record => record.meta.isPublic) && !Store.getters.getLoginStat) {
  //   next({ path: '/login', query: { redirect: to.fullPath }});
  // } else {
  //   next();
  // }

  if (Store.getters.getFormChanged){
    const answer = window.confirm("編集中のものは保存されませんが、よろしいですか？");
    if (!answer) {
      next(false);
      return;
    }
    Store.commit('setFormChanged', false);
  }

  if (!to.matched.some(record => record.meta.isPublic) && !Store.getters.getLoginStat) {
    next({ path: '/login', query: { redirect: to.fullPath }});
  } else if (to.matched.some(record => record.meta.isAssociation) && !(Store.getters.getAttribute == 1 || Store.getters.getAttribute == 2)) {
    // 協会(都道府県・日冷倉)ページかつログインユーザに権限なし(都道府県協会or日本冷蔵倉庫協会 以外)
    next({ path: '/top_view', query: { redirect: '/' }});
  } else if (to.matched.some(record => record.meta.isNichireisou) && !(Store.getters.getAttribute == 2)) {
    // 日本冷蔵倉庫協会ページかつログインユーザに権限なし(日本冷蔵倉庫協会 以外)
    next({ path: '/top_view', query: { redirect: '/' }});
  } else {
    next();
  }
});