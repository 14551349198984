export default {
  methods: {

    // ----------------------------------------------------
    // 必須項目
    required: (v) => !!v || "必須項目です",

    required_ns: (v) => (!!v && v.trim().length > 0) || "必須項目です",

    // 条件付き 必須項目
    requiredIf: (predicate) => (v) => !predicate() || !!v || "必須項目です",

    requiredIf_ns: (predicate) => (v) => !predicate() || (!!v && v.trim().length > 0) || "必須項目です",

    requiredObject: (v) => Object.keys(v).length > 0 || "必須項目です",

    requiredSelect: (v) => !!v || v === 0 || "必須項目です",

    requiredIf_select: (predicate) => (v) => !predicate() || !!v || v === 0 || "必須項目です",

    maxCharLength: (n) => (v) => v === undefined || v === null || v.length <= n || `${n}文字以内で入力してください`,


    // ----------------------------------------------------
    // SJISバイト数での 文字数
    maxLength: (n) => (v) => {
      let length = 0;
      if(v === undefined || v === null) {
        return true;
      }
      for (var i = 0; i < v.length; i++) {
        const c = v.charCodeAt(i);
        if ((c >= 0x0 && c < 0x81) || c === 0xf8f0 || (c >= 0xff61 && c < 0xffa0) || (c >= 0xf8f1 && c < 0xf8f4)) {
          length += 1;
        } else {
          length += 2;
        }
      }

      return v === null || length <= n || `半角 ${n}文字以内で入力してください（${length}文字）`;
    },
    maxLengthIf: (ifFlg, n) => (v) => {
      if(!ifFlg) return true;

      let length = 0;
      if(v === undefined || v === null) {
        return true;
      }
      for (var i = 0; i < v.length; i++) {
        const c = v.charCodeAt(i);
        if ((c >= 0x0 && c < 0x81) || c === 0xf8f0 || (c >= 0xff61 && c < 0xffa0) || (c >= 0xf8f1 && c < 0xf8f4)) {
          length += 1;
        } else {
          length += 2;
        }
      }

      return v === null || length <= n || `半角 ${n}文字以内で入力してください（${length}文字）`;
    },

    // ----------------------------------------------------
    // 最大値
    maxVal: (max) => (v) => {
      if (v === null) return true
      return v <= max || `${max} よりも小さい値を設定してください`;
    },
    maxValIf: (ifFlg, max) => (v) => {
      if(!ifFlg) return true;

      if (v === null) return true
      return v <= max || `${max} よりも小さい値を設定してください`;
    },

    // 最小値
    minVal: (min) => (v) => {
      if (v === null) return true
      return v >= min || `${min} よりも大きい値を設定してください`;
    },
    minValIf: (ifFlg, min) => (v) => {
      if(!ifFlg) return true;

      if (v === null) return true
      return v >= min || `${min} よりも大きい値を設定してください`;
    },

    // カンマ区切りの最大値
    maxValLocaleJP: (max) => (v) => {
      if (v === null) return true
      let tmp = Number(v.replaceAll(',', ''));
      return tmp <= max || `${max} よりも小さい値を設定してください`;
    },
    maxValLocaleJPIf: (ifFlg, max) => (v) => {
      if(!ifFlg) return true;

      if (v === null) return true
      let tmp = Number(v.replaceAll(',', ''));
      return tmp <= max || `${max} よりも小さい値を設定してください`;
    },
    // カンマ区切りの最小値
    minValLocaleJP: (min) => (v) => {
      if (v === null) return true
      let tmp = Number(v.replaceAll(',', ''));
      return tmp >= min || `${min} よりも大きい値を設定してください`;
    },
    minValLocaleJPIf: (ifFlg, min) => (v) => {
      if(!ifFlg) return true;

      if (v === null) return true
      let tmp = Number(v.replaceAll(',', ''));
      return tmp >= min || `${min} よりも大きい値を設定してください`;
    },


    // ----------------------------------------------------
    // URL形式
    validURL: (v) => {
      if ((v == null) || (v == '')) return true
      const reg = /^(https?|ftp):\/\/([a-zA-Z0-9.-]+(:[a-zA-Z0-9.&%$-]+)*@)*((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9][0-9]?)(\.(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9]?[0-9])){3}|([a-zA-Z0-9-]+\.)*[a-zA-Z0-9-]+\.(com|edu|gov|int|mil|net|org|biz|arpa|info|name|pro|aero|coop|museum|[a-zA-Z]{2}))(:[0-9]+)*(\/($|[a-zA-Z0-9.,?'\\+&%$#=~_-]+))*$/;
      return reg.test(v) || `URLの形式で設定してください`;
    },

    // メール形式
    validEmail: (email) => {
      if (email == null || email == '') return true;
      const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email)|| `メールアドレスの形式で設定してください`;
    },

    // 半角カナのみ
    validHKana: (kana) => {
      if (kana == null || kana == '') return true;
      const re = /^[ｦ-ﾟ ]+$/;
      return re.test(kana)|| `半角カナで入力してください`;
    },

    // 全角カナのみ
    validZKana: (kana) => {
      if (kana == null || kana == '') return true;
      const re = /^[ア-ン゛゜ァ-ォャ-ョー「」、]+$/;
      return re.test(kana)|| `半角カナで入力してください`;
    },

    // 数値のみ
    validNumber: (num) => {
        if (num == null || num == '') return true;
        const re = /^[0-9]+$/;
        return re.test(num)|| `半角数字で入力してください`;
        },

  },

};
