import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import rules from './rules'

import vuetify from './plugins/vuetify';
import VModal from 'vue-js-modal';
import VueCookies from 'vue-cookies';
import DatetimePicker from 'vuetify-datetime-picker';

// Axios に interceptors を設定
import './plugins/axios';

import Util from "./Lib/Util";

Vue.config.productionTip = false;
Vue.use(VModal);
Vue.use(VueCookies);
Vue.use(DatetimePicker);
Vue.mixin(rules);
// グローバルフィルタ
// Vue.filter('convHourMinSec', Util.convHourMinSec);
Vue.filter('numberFormat', Util.numberFormat);


// 開発モードでは、DEBAG出力を行う
// ルートディレクトリに、".env.development" が必要
if (process.env.VUE_APP_DEBUG_MODE == "true") {
  console.log(process.env);
}

new Vue({
  router,
  store,
  vuetify,
  VModal,
  render: h => h(App)
}).$mount('#app')
