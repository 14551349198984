import Debug from './Debug'
export default {

    // 今日を取得
    getToday () {
        const dObj = new Date();
        const y = String(dObj.getFullYear());
        const m = String(100 + dObj.getMonth() + 1).substr(1,2);
        const d = String(100 + dObj.getDate()).substr(1,2);
        const today = y + '-' + m + '-' + d;

        return today;
    },

    // 現在日時を取得
    getNowDateTime () {
        const date = new Date();
        const y = String(date.getFullYear());
        const m = String(100 + date.getMonth() + 1).substr(1,2);
        const d = String(100 + date.getDate()).substr(1,2);
        const hr = String(100 + date.getHours()).substr(1,2);
        const min = String(100 + date.getMinutes()).substr(1,2);
        const sec = String(100 + date.getSeconds()).substr(1,2);
        const today = y + m + d + hr + min + sec;

        return today;
    },

    // 今月を取得
    getThisMonth () {
        var dObj = new Date();
        var y = String(dObj.getFullYear());
        var m = String(100 + dObj.getMonth() + 1).substr(1,2);

        return y + '-' + m;
    },

    // Date->String(年月)変換
    convMonthString (date) {
        var y = String(date.getFullYear());
        var m = String(100 + date.getMonth() + 1).substr(1,2);

        return y + '-' + m;
    },

    // Date->String(年月日)変換
    convDateString (date) {
        var y = String(date.getFullYear());
        var m = String(100 + date.getMonth() + 1).substr(1,2);
        const d = String(100 + date.getDate()).substr(1,2);
        const dateS = y + '-' + m + '-' + d;

        return dateS;
    },

    // Date->String(年月日 時分秒)変換
    convDateTimeString (date) {
        const y = String(date.getFullYear());
        const m = String(100 + date.getMonth() + 1).substr(1,2);
        const d = String(100 + date.getDate()).substr(1,2);
        const hr = String(100 + date.getHours()).substr(1,2);
        const min = String(100 + date.getMinutes()).substr(1,2);
        const sec = String(100 + date.getSeconds()).substr(1,2);
        const dateS = y + '-' + m + '-' + d + ' ' + hr + ':' + min + ':' + sec;

        return dateS;
    },

    // Date->String(年月日 時分秒)変換 (DELIM無し)
    convDateTimeStringNoDelim (date) {
        const y = String(date.getFullYear());
        const m = String(100 + date.getMonth() + 1).substr(1,2);
        const d = String(100 + date.getDate()).substr(1,2);
        const hr = String(100 + date.getHours()).substr(1,2);
        const min = String(100 + date.getMinutes()).substr(1,2);
        const sec = String(100 + date.getSeconds()).substr(1,2);
        const dateS = y + m + d + hr + min + sec;

        return dateS;
    },

    // String(年月日)->Date変換
    convStringDate (str, delim) {
        var arr = str.split(delim)
        return new Date(arr[0], arr[1] - 1, arr[2]);
    },

    // String(年月日時分秒) 区切り無し->Date変換
    convStringDateNoDelim (str) {
        const y = Number(str.substring(0, 4));
        const m = Number(str.substring(4, 6));
        const d = Number(str.substring(6, 8));
        const hr = Number(str.substring(8, 10));
        const min = Number(str.substring(10, 12));
        const sec = Number(str.substring(12, 14));

        return new Date(y, m - 1, d, hr, min, sec);
    },

    convHourMinSec(seconds) {
        if (seconds === null) {
            return ""
        }

        if (seconds < 60) {
            // 秒しかない
            return `0:00:${String(seconds).padStart(2, "0")}`;
        }

        if (seconds < 60 * 60) {
            // 分まで
            const min = Math.trunc(seconds / 60);
            const sec = seconds % 60;
            return `0:${String(min).padStart(2, "0")}:${String(sec).padStart(2, "0")}`;
        }

        const hour = Math.trunc(seconds / (60 * 60));
        const min = Math.trunc((seconds % (60 * 60)) / 60);
        const sec = seconds - hour * 60 * 60 - min * 60;
        return `${hour}:${String(min).padStart(2, "0")}:${String(sec).padStart(2, "0")}`;
    },

    /**
     * number 
     */
    // 数字のカンマ区切り(3桁ごと)
    numberFormat(value) {
        if((value === null) ||
            (value === undefined)) {
            return value;
        }
        if (!value.match(/^\d+$/)) {
            return value;
        }
        let formatter = new Intl.NumberFormat('ja-JP');
        return formatter.format(value);
    },

    // 数字のみ(-の許容、小数点の許容、小数点以下桁数の指定可能)
    numOnly(value, minus=false, decimal=false, decimalNum=0) {
        // 正規表現オブジェクト
        const minusReg = minus ? '-' : '';
        const decimalReg = decimal ? '.' : '';
        const regObj = new RegExp('[^0-9'+minusReg+decimalReg+']', 'g');

        // 正規表現で指定された文字列のみ許可
        value = value.replace(regObj, '');

        // 小数点以下桁数の指定があれば入力制御
        if(decimal && (decimalNum > 0) ){
            const numRegObj = new RegExp('[0-9]*.[0-9]{'+(decimalNum+1)+',}', 'g');
            if(value.match(numRegObj)) {
                // 指定の小数点以下桁数までは入力可能
                value = value.slice(0, -1);
            }
        }

        Debug.log('numOnlyValue:'+value);
        return value;
    },

    /**
     * math 
     */
    /**
     * 任意の桁で四捨五入
     * @param {number} value 四捨五入する値
     * @param {number} base 四捨五入桁（1→小数第1位、10→小数第2位、100→小数第3位）
     * @return {number} 結果
     */
    orgRound(value, base) {
        if(typeof value !== "undefined" && value !== null) {
            return Math.round(value * base) / base;
        }

        return null;
    },
}