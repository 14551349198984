export default {

  err(error, mes, alartmes = true) {
    console.error(mes);
    console.error(JSON.stringify(error));
    // console.error(error);

    if (alartmes) {
      alert(mes + "\r\n" + error.message);
    }
  }

}