export default {
  /** デバッグモードかどうか。本番公開時にはfalseにする */
  DEBUG_MODE: process.env.VUE_APP_DEBUG_MODE == "true",

  /** デバッグモードでConsoleAPIが有効な場合にログを出力する */ 
  log(s) {
    if (this.DEBUG_MODE && typeof console.log != "undefined") {
        console.log(s);
    }
  },

  log2(s, obj) {
    if (this.DEBUG_MODE && typeof console.log != "undefined") {
        console.log(s + " %o", obj);
    }
  },

  warn(s) {
    if (this.DEBUG_MODE && typeof console.log != "undefined") {
        console.warn(s);
    }
  }

}