import '@mdi/font/css/materialdesignicons.css' // Ensure you are using css-loader
import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import ja from 'vuetify/es5/locale/ja';
import colors from "vuetify/lib/util/colors";

Vue.use(Vuetify);

export default new Vuetify({
    treeShake: true,
    customVariables: ['../sass/variables.scss'],

    icons: {
      iconfont: 'mdi', // default - only for display purposes
    },
    lang: {
      locales: { ja },
      current: 'ja',
    },

    theme: {
      dark: false,
  
      themes: {
        light: {
          primary: '#003f8e',
          secondary: '#ff0000',
          input: colors.green.accent1,
          accent: colors.blue.lighten1,
          warning: colors.red.lighten1,
          info: colors.blue.lighten3,
          info_hover: colors.blue.lighten5,
          attention: colors.yellow.lighten1,
          dimgray: '#696969',
          modal_background: '#FFFFFF',
          disable: colors.grey.lighten1,
          table_hover: '#E0E0E0',
        },
        dark: {
          primary: '#FFFFFF',
          secondary: '#ff0000',
          input: colors.green.accent1,
          accent: colors.blue.lighten1,
          warning: colors.red.lighten1,
          info: colors.blue.lighten3,
          attention: colors.yellow.lighten1,
          dimgray: '#696969',
          modal_background: '#2F2F2F',
          disable: colors.grey.lighten1,
          table_hover: '#E0E0E0',
        },

      },
      options: {
        customProperties: true
      },
    },
    breakpoint: {
      thresholds: {
        xs: 340,
        sm: 540,
        md: 1650,
        lg: 1920,
      }
    },
});
