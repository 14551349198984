<template>
  <v-app>
    <div>
      <!-- サイドバー -->
      <router-view name="sidebar"></router-view>
    </div>

    <!-- <v-main class="content">
      <router-view></router-view>
    </v-main> -->

    <v-main class="content">
      <transition name="fade">
        <!-- パラメータ違いでも画面遷移する -->
        <router-view :key="$route.fullPath"></router-view>
      </transition>
    </v-main>

  </v-app>
</template>

<script>

export default {
  name: 'App',

  components: {
  },

  data: () => ({
  }),
};
</script>


<style>
html {
  overflow: auto !important;
  /* width: 1920px; */
  /* height: 1080px; */

  /* スマホは考えない */
  min-width: 1000px !important;
}

body {
	margin: 0;
	padding: 0;
  font-size: 16px;
  font-family: "ヒラギノ角ゴ ProN W6", "Hiragino Kaku Gothic Pro", "游ゴシック Medium",
        "Yu Gothic Medium", "メイリオ", "Meiryo", sans-serif;
}

.container {
  min-width: 95% !important;
  width: 95% !important;
}

.content {
  /* height: 1080px; */
  padding: 0;
  margin: 0;
  overflow: auto;
  /* background-color: #200040; */
}

.nowrap {
  white-space: nowrap;
}

.preline {
  white-space: pre-line;
}


.virtical-center {
  display: table-cell;
  vertical-align: middle;
}

.label-center {
  display: flex;
  align-items: center;
  border: 1px solid darkgray;
  width: 200px;
  height: 40px;
  padding: 3px;
  margin-right: 5px;
  background: #F0F0F0;
}

.flex-layout {
  display: flex;
}


/* input[text] */
.readonly-text {
  border: 1px solid darkgray;
  padding: 3px;
  margin-right: 5px;
}

.centered-input input {
  text-align: center;
}
.right-input input {
  text-align: right;
}

.center {
  text-align: center !important;
}
.left {
  text-align: left !important;
}
.right {
  text-align: right !important;
}

.break-all {
  word-break : break-all
}

.min-width-200 {
  min-width: 200px;
}

.v-menu__content {
  border: 2px solid #888888 !important;
}

@media print{
  .no_print{
      display: none !important;
  }
}

/* vue-js-modal の背景色を設定 (Vuetify のカラーを設定) */ 
.vm--modal {
  background-color: var(--v-modal_background-base)  !important;
}

/* modal dialog */
.modal-border {
  border:solid 3px #555555;
}
.modal-header, .modal-body {
  padding: 5px 25px;
}
.modal-header {
  border-bottom: 1px solid #ddd;
}

.table-border td,
.table-border th 
{
  /* border-top: solid 1px #555;
  border-bottom: solid 1px #555;
  border-left: solid 1px #333;
  border-right: solid 1px #333; */
  border: 1px solid #888888;
}

table {
  table-layout: fixed;
}

table td {
  /* word-break: break-all; */
  overflow-wrap : break-word;
}

.v-messages__message {
  white-space: pre-line !important;
  line-height: 1.5 !important;
}

/* トランジションアニメーション */
/* フェード ココから*/
.fade-enter-active /*, .fade-leave-active*/ {
  will-change: opacity;
  /* transition: opacity 1s cubic-bezier(0.4, 0, 0.2, 1) 0ms; */
  transition: opacity 2s;
}

/* チャートが位置ずれするので、すぐに消す */
.fade-leave-active {
  display: none;
}

.fade-enter, .fade-leave-to {
  opacity: 0
}
/* フェード ここまで*/

/* エラーテキスト(赤文字) */
.text-error {
  color: #ff0000;
}

/* ワーニングテキスト(黄文字) */
.text-warning {
  color: #ffff00;
}

/* インフォテキスト(青文字) */
.text-info {
  color: var(--v-accent-base);
}

/* テーブルレコード */
.disable-color {
  background: #cccccc;
}

.error-color {
  background: #ffaaaa;
}

.warning-color {
  background: #FFF9C4;
}

.selected-color {
  background: #BBDEFB;
}

</style>